import {call, put, takeEvery, all, takeLatest} from "redux-saga/effects";
import { getReguest } from "../initData/api";

import {
    addShop,
    addShopSuccess,
    addShopFailure,
    addShopIdle,
    updateShop,
    updateShopSuccess,
    updateShopFailure,
    updateShopIdle,
} from "./actions";

import { addReguest, updateReguest } from "./api";

function* addShopSaga(action) {
    try {

        let { payload } = action;

        let categories = payload.data.Categories;
        let categoriesIds = [];

        let isShop = yield call(getReguest, { route: '/api/shops?filters[Title][$contains]=' + payload.data.Title });

        let shop;

        if(isShop && !isShop.data.length){
            if(categories && categories.length > 0){
                let isCategory = yield all(categories.map(item => call(getReguest, { route: '/api/categories?filters[Title][$contains]=' + item })));

                categories.map((item, index) => {
                    if(isCategory[index].data && isCategory[index].data.length > 0){
                        categoriesIds.push(isCategory[index].data[0].id);
                    }
                });

                let noneCategories = [];
                categories.map((item, index) => {
                    if(isCategory[index].data && !isCategory[index].data.length){
                        noneCategories.push(item);        
                    }
                })

                let noneCategoriesData = [];
                if(noneCategories && noneCategories.length > 0){
                    noneCategoriesData = yield all(noneCategories.map(item => call(addReguest, { route: '/api/categories', payload: { data: { Title: item } } })));
                }

                if(noneCategoriesData && noneCategoriesData.length > 0){
                    noneCategoriesData.map((item, index) => {
                        if(noneCategoriesData[index].data && noneCategoriesData[index].data.id){
                            categoriesIds.push(noneCategoriesData[index].data.id);
                        }
                    });
                }
            }

            shop = yield call(addReguest, { route: '/api/shops', payload: {
                ...payload,
                data: {
                    ...payload.data,
                    Categories: categoriesIds
                }
            } });
        }

        yield put(addShopSuccess(shop));

    } catch (error) {

        yield put(addShopFailure(error));

    }
}

function* updateShopSaga(action) {
    try {

        let { payload } = action;

        yield call(updateReguest, { route: '/api/shops/'+payload.id, payload: {
            ...payload,
            data: {
                ...payload.data,
            }
        } });

        yield put(updateShopSuccess());

    } catch (error) {

        yield put(updateShopFailure(error));

    }
}


export function* addShopWatcher() {
    yield takeEvery(addShop.toString(), addShopSaga);
}

export function* updateShopWatcher() {
    yield takeEvery(updateShop.toString(), updateShopSaga);
}
