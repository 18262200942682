import React, { useEffect } from "react";

// import './style.css'

import Inner from "../../layout/inner";
import Results from "./components/results";

import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom";
import { getCategory, getCategoryIdle } from "../../modules/category/actions";

const Category = (props) => {

    const dispatch = useDispatch();

    const status = useSelector(state => state.category.status);
    const currentCity = useSelector(state => state.initData.city);
    const categoryInfoById = useSelector(state => state.initData.categoriesById);

    const params = useParams();

    useEffect(() => {
        if(status == 'SUCCESS'){
            dispatch(getCategoryIdle());
        }
    }, [status])

    useEffect(() => {
        if(status == 'IDLE' && categoryInfoById){
            dispatch(getCategory({
                id: params.id,
                // topShops: categoryInfoById && categoryInfoById[params.id] && categoryInfoById[params.id].Top_shops || null,
                currentCity: currentCity
            }))
        }
    }, [params.id, categoryInfoById])

    return (
        <Inner
            {...props}
            content={<Results id={params.id} />}
            dopClass={'_category'}
        ></Inner>
    )
}

export default Category;