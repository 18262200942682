// let token = '401ba43cd44999e02af3b2be883be7c3e1c8053ca41a5c208bf1d7657c4727d4aacd753e176c494ba672eed008b6f6fa9e56df0be675c700623c0cd5b0ac1e60e30e3fc03673d0007d37df443a3d373ea5b58e667bcccf7a2dfd3a6dce8781e9442c2bc7dc7f28f9383d7d27bdf62cddc853a23bb1599095e303c5bd6b9322cd';

let token = 'e88505578abed9ff7a0fbc259b46657bfc4b9602037c1e0fdbcae6336071b36afeb98830ed3139b16386a1c8848db45a3f552be1899410aab0e83a7c6254b82a964236a0ae1d31baf2ef4c5b05681355c2664897210d52109d3677b558d194e58bc4f30ff447c2d640a1413e9889fb228d88810fa25ff4a3fffff750ee0d810b';

let domain = 'https://podeli-api.dev.co-dd.dev';

if(window.location.host == 'localhost:3000'){
    // token = 'e88505578abed9ff7a0fbc259b46657bfc4b9602037c1e0fdbcae6336071b36afeb98830ed3139b16386a1c8848db45a3f552be1899410aab0e83a7c6254b82a964236a0ae1d31baf2ef4c5b05681355c2664897210d52109d3677b558d194e58bc4f30ff447c2d640a1413e9889fb228d88810fa25ff4a3fffff750ee0d810b'; // DEV
    // // token = 'e4faff52c85558364b64313697fa6378364189bfe5bb5aa3d176e54e64af70ec5ee0724ea4032abaa0cb384ed4931c4c2b3e76b74a886ad9a805ee24a227af0461f830bd22dd3dc00ca3e1df1be35748da654e3ea45933fce3789ce2a25a5f15a1ba263f1095fbe411538a72e9a28f60628810b8eb9f3d0640b0f67257ab5c78';
}

if(
    // window.location.host == 'localhost:3000' || 
    window.location.host == 'podeli-shop.sandbox.linups.online'
){
    token = 'e88505578abed9ff7a0fbc259b46657bfc4b9602037c1e0fdbcae6336071b36afeb98830ed3139b16386a1c8848db45a3f552be1899410aab0e83a7c6254b82a964236a0ae1d31baf2ef4c5b05681355c2664897210d52109d3677b558d194e58bc4f30ff447c2d640a1413e9889fb228d88810fa25ff4a3fffff750ee0d810b'; // DEV
    // token = 'e4faff52c85558364b64313697fa6378364189bfe5bb5aa3d176e54e64af70ec5ee0724ea4032abaa0cb384ed4931c4c2b3e76b74a886ad9a805ee24a227af0461f830bd22dd3dc00ca3e1df1be35748da654e3ea45933fce3789ce2a25a5f15a1ba263f1095fbe411538a72e9a28f60628810b8eb9f3d0640b0f67257ab5c78';
}

if (
    window.location.host == "podeli.ru" || 
    window.location.host == "pdeli.ru" ||
    window.location.host == 'localhost:3000'
    // window.location.host == 'podeli-shop.sandbox.linups.online'
) {
    token = '16a1c2b8513c363074a3a63b2b653993249294dbfaac13c374d87f849f0230bd5ae54fbdc3e7fd6bcab7f46097e9270aae9c9181dbca97ae6aa7eeb14e54bb44bafe80723f69fb95e5b32ea9b532a02fc2949c848afd3e067bf33ce4b384129aba85352dc5ad51ddf77c5b407f7390d673a663592da58a2d51744b27f9c454f4';
    domain = 'https://podeli-api.co-dd.ru';
}

export {domain, token}

let menu = [
    {
        link: '/',
        title: 'Магазины',
        icon: 'menu-icon-1.svg'
    },{
        link: '/pokupki',
        title: 'Покупки',
        icon: 'menu-icon-2.svg'
    },{
        link: '/qr',
        title: 'QR оплата',
        icon: 'menu-icon-3.svg'
    },{
        link: '/chat',
        title: 'Чат',
        icon: 'menu-icon-4.svg'
    },{
        link: '/profile',
        title: 'Профиль',
        icon: 'menu-icon-5.svg'
    }
]

if(window.location.pathname.indexOf('testshops')){
    menu = [
        {
            link: '/testshops',
            title: 'Магазины',
            icon: 'menu-icon-1.svg'
        },{
            link: '/testshops/pokupki',
            title: 'Покупки',
            icon: 'menu-icon-2.svg'
        },{
            link: '/testshops/qr',
            title: 'QR оплата',
            icon: 'menu-icon-3.svg'
        },{
            link: '/testshops/chat',
            title: 'Чат',
            icon: 'menu-icon-4.svg'
        },{
            link: '/testshops/profile',
            title: 'Профиль',
            icon: 'menu-icon-5.svg'
        }
    ]
}

export { menu };

export const cites = [
    {
        value: 'all',
        title: 'Вся Россия'
    },
    {
        value: 'moscow',
        title: 'Москва'
    },
    {
        value: 'spb',
        title: 'Санкт-Петербург'
    },{
        value: 'Абаза',
        title: 'Абаза'
    },{
        value: 'Абакан',
        title: 'Абакан'
    },{
        value: 'Абдулино',
        title: 'Абдулино'
    },{
        value: 'Абинск',
        title: 'Абинск'
    },{
        value: 'Агидель',
        title: 'Агидель'
    },{
        value: 'Агрыз',
        title: 'Агрыз'
    },{
        value: 'Адлер',
        title: 'Адлер'
    },{
        value: 'Адыгейск',
        title: 'Адыгейск'
    },{
        value: 'Азнакаево',
        title: 'Азнакаево'
    },{
        value: 'Азов',
        title: 'Азов'
    }
]

export const filterType = [
    {
        title: 'Интернет-магазины',
        value: 'Интернет-магазины'
    },
    {
        title: 'Розничные магазины',
        value: 'Розничные магазины'
    }
]

export const filterCategory = [
    {
        title: 'Электроника и бытовая техника',
        value: 'Электроника и бытовая техника'
    },
    {
        title: 'Косметика',
        value: 'Косметика',
        icon: '/assets/img/icon-filter-flower.svg'
    },
    {
        title: 'Одежда',
        value: 'Одежда',
        icon: '/assets/img/icon-filter-tshirt.svg'
    },
    {
        title: 'Обувь',
        value: 'Обувь',
        icon: '/assets/img/icon-filter-sneaker.svg'
    },
    {
        title: 'Категория 1',
        value: 'Категория 1'
    },
    {
        title: 'Категория 2',
        value: 'Категория 2'
    },
    {
        title: 'Категория 3',
        value: 'Категория 3'
    }
]

export const getUrlImage = (props) => {
    let result = '';
    if(props.data && props.data.attributes && props.data.attributes.url){
        result = domain + props.data.attributes.url;
    }
    return result;
}

export const targetMetrick = (target) => {
    if(window.ym){
        window.ym(93798687, 'reachGoal', target);
    }
}