import React, { useEffect, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import { targetMetrick } from "../../constants/config";
import { setIdSale } from "../../modules/sale/actions";

// import './style.css';

const PostSale = (props) => {

    let refSalePreview = useRef(null);

    const { id, link, title, description, logo, price, type, preview, target, attributes } = props;

    let Web_store = null;
    let Retail_store = null;

    if(attributes && attributes.Sale_card){
        
        Web_store = attributes.Sale_card.Web_store;
        Retail_store = attributes.Sale_card.Retail_store;

    }

    const dispatch = useDispatch();

    const handleShowSale = (e) => {

        if(!link || link == '#'){
            e.preventDefault();
            dispatch(setIdSale({ id }));
        }

        targetMetrick(target && target || title);

    }

    useEffect(() => {
        if(refSalePreview && preview){
            refSalePreview.current.style.backgroundImage = 'url(' + preview + ')';
        }
    }, [preview])

    return (
        <a ref={refSalePreview} href={link && link || '#'} onClick={handleShowSale} target={link && '_blank' || ''} className='post _sale'>
            {type == 'top' && (
                <>
                    <span className='post__title' dangerouslySetInnerHTML={{ __html: title }}>
                    </span>
                    <span className='post__description' dangerouslySetInnerHTML={{ __html: description }}>
                    </span>
                </>
            ) || (
                <>
                    {logo && (
                        <span className='post__logo'>
                            <img src={logo} />
                        </span>
                    )}
                    {price && (
                        <span className='post__price'>
                            {price}
                        </span>
                    )}
                    {( Web_store || Retail_store ) && (
                        <span className="post__shops">
                            {Retail_store && (
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.00647 7.4798V10.4731C2.00647 13.4665 3.20647 14.6665 6.1998 14.6665H9.79314C12.7865 14.6665 13.9865 13.4665 13.9865 10.4731V7.4798" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M8.00036 7.99998C9.22036 7.99998 10.1204 7.00665 10.0004 5.78665L9.56036 1.33331H6.44703L6.00036 5.78665C5.88036 7.00665 6.78036 7.99998 8.00036 7.99998Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M12.2065 7.99998C13.5532 7.99998 14.5399 6.90665 14.4065 5.56665L14.2199 3.73331C13.9799 1.99998 13.3132 1.33331 11.5665 1.33331H9.5332L9.99987 6.00665C10.1132 7.10665 11.1065 7.99998 12.2065 7.99998Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M3.76029 7.99998C4.86029 7.99998 5.85362 7.10665 5.96029 6.00665L6.10695 4.53331L6.42695 1.33331H4.39362C2.64695 1.33331 1.98029 1.99998 1.74029 3.73331L1.56029 5.56665C1.42695 6.90665 2.41362 7.99998 3.76029 7.99998Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M8.00004 11.3333C6.88671 11.3333 6.33337 11.8866 6.33337 13V14.6666H9.66671V13C9.66671 11.8866 9.11337 11.3333 8.00004 11.3333Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>                                
                            )}
                            {Web_store && (
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.00004 14.6666C11.6819 14.6666 14.6667 11.6819 14.6667 7.99998C14.6667 4.31808 11.6819 1.33331 8.00004 1.33331C4.31814 1.33331 1.33337 4.31808 1.33337 7.99998C1.33337 11.6819 4.31814 14.6666 8.00004 14.6666Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M5.33336 2H6.00002C4.70002 5.89333 4.70002 10.1067 6.00002 14H5.33336" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M10 2C11.3 5.89333 11.3 10.1067 10 14" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M2 10.6667V10C5.89333 11.3 10.1067 11.3 14 10V10.6667" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M2 6.00002C5.89333 4.70002 10.1067 4.70002 14 6.00002" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>                                
                            )}
                        </span>
                    )}
                </>
            )}
        </a>
    )
}

export default PostSale;