import { token, domain } from "../../constants/config";

export const addReguest = ({ route, payload }) => {

    return new Promise((resolve, reject) => {

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify(payload);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            // body: null,
            body: raw,
            redirect: 'follow',
        };

        fetch(domain + route, requestOptions)
            .then(response => response.json())
            .then(result => {
                resolve(result)
            })
            .catch(error => {
                reject(error);
            });
    
    });

    
}

export const updateReguest = ({ route, payload }) => {

    return new Promise((resolve, reject) => {

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify(payload);

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            // body: null,
            body: raw,
            redirect: 'follow',
        };

        fetch(domain + route, requestOptions)
            .then(response => response.json())
            .then(result => {
                resolve(result)
            })
            .catch(error => {
                reject(error);
            });
    
    });

    
}