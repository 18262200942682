import {call, put, takeEvery, all, takeLatest} from "redux-saga/effects";

import { getReguest } from '../initData/api';

import {
    getShop,
    getShopSuccess,
    getShopFailure,
    getShopIdle,
} from "./actions";

function* getShopSaga(action) {
    try {

        let { payload } = action;

        const { id } = payload; 

        let populate = 'populate[Store_types][populate]=*'+
            '&populate[Categories][populate]=*'+
            '&populate[Logo][populate]=*'+
            '&populate[Preview][populate]=*'+
            '&populate[Sale][populate]=*'+
            '&populate[Sale][Sale_card][populate]=*'+
            '&populate[Sale_card][populate]=*'+
            '&populate[Sale_alpha][populate]=*'+
            '&populate[Alpha_card][populate]=*'+
            '&populate[Sale_alpha][populate]=Alpha_card.Preview'+
            '&populate[Sale_alpha][populate]=Alpha_card.Title_on_gray_group'+
            '&populate[Sale_alpha][populate]=Alpha_card.SubTitle_on_gray_group';

        const [
            shop
        ] = yield all([
            call(getReguest, { route: "/api/shops/" + id + "/?" + populate }),
        ]);

        yield put(getShopSuccess(shop));

    } catch (error) {

        yield put(getShopFailure(error));

    }
}


export function* getShopWatcher() {
    yield takeLatest(getShop.toString(), getShopSaga);
}
