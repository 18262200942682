import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { data } from '../../constants/data';
import { shops } from '../../constants/shops';
import { addShop, updateShop } from '../../modules/import/actions';

const Import = () => {

    const status = useSelector(state => state.initData.status);
    const dispatch = useDispatch();

    useEffect(() => {

        if(status == 'SUCCESS'){


            shops.Shops.map(item => {
                dispatch(updateShop({
                    "id": item.id,
                    "data": {
                        Description: item.Description,
                        Subtitle: item.Subtitle,
                        Title: item.Title
                    }
                }));
            })

            // let doc = document.createElement('div');
            // doc.innerHTML = data;

            // const dataCorrect = [];

            // doc.querySelectorAll('tr').forEach((tr, index) => {
            //     if(tr.querySelectorAll('.s6')){
            //         let tds = tr.querySelectorAll('td');
            //         let item = {
            //             Title: '',
            //             Categories: [],
            //             Description: '',
            //             Keywords: '',
            //             Web_store: ''
            //         };
            //         let state = true;
            //         tds.forEach((td, index) => {
            //             if(index == 4){
            //                 item = {
            //                     ...item,
            //                     Title: td.innerHTML
            //                 }
            //             }
            //             if(index == 8){
            //                 item = {
            //                     ...item,
            //                     Categories: td.innerHTML.split('<br>')
            //                 }
            //             }
            //             if(index == 9){
            //                 item = {
            //                     ...item,
            //                     Description: td.innerHTML
            //                 }
            //             }
            //             if(index == 10){
            //                 item = {
            //                     ...item,
            //                     Keywords: td.innerText.replaceAll('\n',' ')
            //                 }
            //             }
            //             if(index == 11){
            //                 item = {
            //                     ...item,
            //                     Web_store: td.innerText
            //                 }
            //             }
            //             if(td.innerHTML == 'наименование' || td.innerHTML == 'Убрать' || td.innerHTML == 'убрать' ){
            //                 state = false;
            //             }
            //         });
            //         if(item.Title && state){
            //             dataCorrect.push(item)
            //         }
            //     }
            // });
            
            // dataCorrect.map(item => {
            //     dispatch(addShop({
            //         "data": item
            //     }));
            // })

        }

    }, [status])

    return (
        <div></div>
    )
}

export default Import;