import {handleActions} from "redux-actions";
import {combineReducers} from "redux";
import {
    getInitData,
    getInitDataSuccess,
    getInitDataFailure,
    getInitDataIdle,
    setCity,
    setSearch
} from "./actions";

const status = handleActions(
    {
        [getInitDataSuccess]: () => "SUCCESS",
        [getInitData]: () => "LOADING",
        [getInitDataFailure]: () => "FAILURE",
        [getInitDataIdle]: () => "IDLE",
    },
    'IDLE'
);

const city = handleActions(
    {
        [setCity]: (state, action) => action.payload,
    },
    {
        id: 'all',
        Title: 'Вся Россия'
    }
)

const search = handleActions(
    {
        [setSearch]: (state, action) => action.payload
    },
    ''
)

const categories = handleActions(
    {
        [getInitDataSuccess]: (state, action) => action.payload.categories.data,
    },
    null
)

const categoriesById = handleActions(
    {
        [getInitDataSuccess]: (state, action) => action.payload.categoriesById,
    },
    null
)

const categoriesSortById = handleActions(
    {
        [getInitDataSuccess]: (state, action) => action.payload.categoriesSortById,
    },
    null
)

const categoriesWithItemsIds = handleActions(
    {
        [getInitDataSuccess]: (state, action) => action.payload.categoriesWithItemsIds,
    },
    null
)

const storeTypes = handleActions(
    {
        [getInitDataSuccess]: (state, action) => action.payload.storeTypes.data,
    },
    null
)

const topSales = handleActions(
    {
        [getInitDataSuccess]: (state, action) => action.payload.topSales.data,
    },
    null
)

const topAlpha = handleActions(
    {
        [getInitDataSuccess]: (state, action) => action.payload.topAlpha.data,
    },
    null
)

const cities = handleActions(
    {
        [getInitDataSuccess]: (state, action) => [
            {
                id: 'all',
                attributes: {
                    Title: 'Вся Россия'
                }
            },
            ...action.payload.cities.data
        ],
    },
    null
)

const allSalesCount = handleActions(
    {
        [getInitDataSuccess]: (state, action) => action.payload.allSales.data && action.payload.allSales.data.length || null,
    },
    null
)

const allSalesAlphaCount = handleActions(
    {
        [getInitDataSuccess]: (state, action) => action.payload.allSalesAlpha.data && action.payload.allSalesAlpha.data.length || null,
    },
    null
)

const initData = combineReducers({
    status,
    city,
    categories,
    categoriesById,
    categoriesSortById,
    categoriesWithItemsIds,
    storeTypes,
    search,
    cities,
    topSales,
    topAlpha,
    allSalesCount,
    allSalesAlphaCount
});

export {initData};
