import { call, put, takeEvery, all } from "redux-saga/effects";

import { getReguest } from "./api";

import {
  getInitData,
  getInitDataSuccess,
  getInitDataFailure,
  getInitDataIdle,
} from "./actions";

function iOS() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

function* getInitDataSaga(action) {
  try {
    const [
      categories,
      // storeTypes,
      // cities,
      topSales,
      topAlpha,
      allSales,
      allSalesAlpha,
    ] = yield all([
      call(getReguest, {
        route:
          "/api/categories?" +
          // "populate[Top_shops][fields][0]=Title" +
          // "&populate[Top_shops][fields][1]=Subtitle" +
          // "&populate[Top_shops][fields][2]=Show_percent_on_preview" +
          // "&populate[Top_shops][populate]=Preview,Preview_list,Logo,Sort" +
          // "&populate[Top_shops][populate]=Sale.Sale_card" +
          // "&populate[Top_shops][populate]=Sale_card" +
          "populate[Shops][populate]=Preview,Preview_list,Logo,Sale_card&" +
          "populate[Icon]=*" +
          "&pagination[pageSize]=100" +
          "&sort=Sort",
      }),
      // call(getReguest, { route: "/api/store-types" }),
      // call(getReguest, { route: "/api/cities" }),
      call(getReguest, {
        route: "/api/sales?sort=Sort&populate[Sale_card][populate]=*",
      }),
      call(getReguest, {
        route:
          "/api/sale-alphas?filters[Top]=1&populate[Alpha_card][populate]=*",
      }),
      call(getReguest, { route: "/api/sales" }),
      call(getReguest, { route: "/api/sale-alphas" }),
    ]);
    const isiOS = iOS();
    if (isiOS) {
      topAlpha = null;
      allSalesAlpha.data = [];
    }

    let categoriesWithItemsIds = [];

    let categoriesById = {};

    let categoriesSortById = [];

    if (categories && categories.data && categories.data.length > 0) {
      categories.data.map((item) => {
        categoriesById = {
          ...categoriesById,
          [item.id]: item.attributes,
        };
      });
      categories.data.map((item) => {
        categoriesSortById.push(item.id);
      });

      // const shopsCategory = yield all(categories.data.map(item => call(getReguest, { route: "/api/shops/?filters[Categories][id][$in]=" + item.id })))

      // categories.data.map((item, index) => {
      //     if(shopsCategory[index].data && shopsCategory[index].data.length > 0){
      //         categoriesWithItemsIds.push(item.id);
      //     }
      // })
    }

    let cities = {
      data: [],
    };

    let storeTypes = {
      data: [
        {
          id: 1,
          attributes: {
            Title: "Интернет-магазины",
          },
        },
        {
          id: 2,
          attributes: {
            Title: "Розничные магазины",
          },
        },
      ],
    };

    console.log(topSales);

    yield put(
      getInitDataSuccess({
        categories,
        categoriesWithItemsIds,
        storeTypes,
        cities,
        topSales,
        topAlpha,
        allSales,
        categoriesById,
        allSalesAlpha,
        categoriesSortById,
      })
    );

    // yield put(setActiveWarehouses(activeWarehouses))
  } catch (error) {
    console.log(error);

    yield put(getInitDataFailure(error));
  }
}

export function* getInitDataWatcher() {
  yield takeEvery(getInitData.toString(), getInitDataSaga);
}
