import { createSelector } from 'reselect';
import { getUrlImage } from '../../constants/config';

const stateShopData = state => state.shop.data;

export const selectorShop = createSelector(stateShopData, data => {

    let shop = {};

    if(data){

        let { attributes } = data;
    
        let { 
            Title, 
            Preview, 
            Logo, 
            Description, 
            Sale_card, 
            Sale, 
            Alpha_card, 
            Sale_alpha,
            Retail_store, 
            Web_store 
        } = attributes;

        let percentSale = null;
        let percentSymbol = null;
        let titleSale = '';
        let organizatorSale = '';
        let promocodeSale = '';
        let infoSale = '';
        let idSale = null;
        let saleWebStore = null;
        let saleRetailStore = null;
        let saleTitleOnGrayGroup = null;
        let saleSubTitleOnGrayGroup = null;
        let saleTitleInner = null;

        if(Sale_card && Sale_card.Percent){
            percentSale = Sale_card.Percent;
            percentSymbol = Sale_card.Percent_symbol;
            titleSale = Sale_card.Title;
            organizatorSale = Sale_card.Organizator;
            promocodeSale = Sale_card.Promocode;
            infoSale = Sale_card.Info;
            saleTitleOnGrayGroup = Sale_card.Title_on_gray_group;
            saleSubTitleOnGrayGroup = Sale_card.SubTitle_on_gray_group;
            if(Sale_card.Web_store){
                saleWebStore = Sale_card.Web_store;
            }
            if(Sale_card.Retail_store){
                saleRetailStore = Sale_card.Retail_store;
            }
            if(Sale_card.Title_inner){
                saleTitleInner = Sale_card.Title_inner;
            }
        } else {
            if(Sale && Sale.data){
                idSale = Sale.data.id;
                if(Sale.data.attributes && Sale.data.attributes.Sale_card){
                    if(Sale.data.attributes.Sale_card.Title_on_gray_group){
                        saleTitleOnGrayGroup = Sale.data.attributes.Sale_card.Title_on_gray_group;
                    }
                    if(Sale.data.attributes.Sale_card.SubTitle_on_gray_group){
                        saleSubTitleOnGrayGroup = Sale.data.attributes.Sale_card.SubTitle_on_gray_group;
                    }
                    if(Sale.data.attributes.Sale_card.Percent){
                        percentSale = Sale.data.attributes.Sale_card.Percent;
                    }
                    if(Sale.data.attributes.Sale_card.Percent_symbol){
                        percentSymbol = Sale.data.attributes.Sale_card.Percent_symbol;
                    }
                    if(Sale.data.attributes.Sale_card.Web_store){
                        saleWebStore = Sale.data.attributes.Sale_card.Web_store;
                    }
                    if(Sale.data.attributes.Sale_card.Retail_store){
                        saleRetailStore = Sale.data.attributes.Sale_card.Retail_store;
                    }
                    if(Sale.data.attributes.Sale_card.Title_inner){
                        saleTitleInner = Sale.data.attributes.Sale_card.Title_inner;
                    }
                }
            }
        }

        let percentSaleAlpha = null;
        let previewSaleAlpha = null;
        let titleSaleAlpha = '';
        let descriptionSaleAlpha = '';
        let linkSaleAlpha = null;
        let saleTitleAlphaOnGrayGroup = '';
        let saleSubTitleAlphaOnGrayGroup = '';

        if(Alpha_card){
            percentSaleAlpha = Alpha_card.Percent;
            if(Alpha_card.Preview){
                previewSaleAlpha = getUrlImage(Alpha_card.Preview);
            }
            if(Alpha_card.Title){
                titleSaleAlpha = Alpha_card.Title;
            }
            if(Alpha_card.Description){
                descriptionSaleAlpha = Alpha_card.Description;
            }
            if(Alpha_card.Link){
                linkSaleAlpha = Alpha_card.Link;
            }
            saleTitleAlphaOnGrayGroup = Alpha_card.Title_on_gray_group;
            saleSubTitleAlphaOnGrayGroup = Alpha_card.SubTitle_on_gray_group;
        } else {
            if(Sale_alpha && Sale_alpha.data){
                percentSaleAlpha = Sale_alpha.data.attributes.Alpha_card.Percent
                if(Sale_alpha.data.attributes.Alpha_card.Preview){
                    previewSaleAlpha = getUrlImage(Sale_alpha.data.attributes.Alpha_card.Preview);
                }
                if(Sale_alpha.data.attributes.Alpha_card.Title){
                    titleSaleAlpha = Sale_alpha.data.attributes.Alpha_card.Title;
                }
                if(Sale_alpha.data.attributes.Alpha_card.Description){
                    descriptionSaleAlpha = Sale_alpha.data.attributes.Alpha_card.Description;
                }
                if(Sale_alpha.data.attributes.Alpha_card.Link){
                    linkSaleAlpha = Sale_alpha.data.attributes.Alpha_card.Link;
                }
                saleTitleAlphaOnGrayGroup = Sale_alpha.data.attributes.Alpha_card.Title_on_gray_group;
                saleSubTitleAlphaOnGrayGroup = Sale_alpha.data.attributes.Alpha_card.SubTitle_on_gray_group;
            }
        }

        shop = {
            title: Title,
            preview: getUrlImage(Preview),
            logo: getUrlImage(Logo),
            description: Description.replaceAll('\n',"&nbsp; \n\n"),
            percentSale: percentSale,
            percentSymbol: percentSymbol,
            idSale: idSale,
            titleSale: titleSale,
            organizatorSale: organizatorSale,
            promocodeSale: promocodeSale,
            infoSale: infoSale,
            percentSaleAlpha: percentSaleAlpha,
            previewSaleAlpha: previewSaleAlpha,
            titleSaleAlpha: titleSaleAlpha,
            descriptionSaleAlpha: descriptionSaleAlpha,
            linkSaleAlpha: linkSaleAlpha,
            Web_store: Web_store,
            Retail_store: Retail_store, 
            saleWebStore: saleWebStore,
            saleRetailStore: saleRetailStore,
            saleTitleOnGrayGroup: saleTitleOnGrayGroup,
            saleSubTitleOnGrayGroup: saleSubTitleOnGrayGroup,
            saleTitleInner: saleTitleInner,
            saleTitleAlphaOnGrayGroup: saleTitleAlphaOnGrayGroup,
            saleSubTitleAlphaOnGrayGroup: saleSubTitleAlphaOnGrayGroup
        };

    }

    return shop;
});