import React, { useEffect, useRef } from "react";
import { targetMetrick } from "../../../../constants/config";

// import './style.css'

const CategoryCheckbox = (props) => {

    const inputRef = useRef(null);

    const { title, icon, value, name, checked, callback, target } = props;

    useEffect(() => {
        if(checked){
            inputRef.current.checked = checked;
        } else {
            inputRef.current.checked = false;
        }
    }, [checked])

    const handleChange = (e) => {
        callback(e);

        if(target){
            targetMetrick('Выбор фильтра.'+target);
        }
    }

    return (
        <label className='checkbox _category'>
            <input ref={inputRef} className='checkbox__input' onChange={handleChange} type="checkbox" value={value} name={name} />
            <span className='checkbox__wrap'>
                {icon && icon !== '' && (
                    <span className='checkbox__icon'>
                        <img src={icon} />
                    </span>
                )}
                <span className='checkbox__title'>{title}</span>
            </span>
        </label>
    )
}

export default CategoryCheckbox;