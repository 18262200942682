import React, { useEffect } from "react";

// import './style.css'

import { useSelector, useDispatch } from 'react-redux';
import { getSearchClear, getSearchIdle } from "../../modules/search/actions";

const Modal = (props) => {
    
    const dispatch = useDispatch();

    const { header, content, dopClass, show, toggle, callbackClose, footer } = props;

    const statusSearch = useSelector(state => state.search.status);

    useEffect(() => {
        if(show){
            document.body.classList.add('_hidden');
        } else {
            document.body.classList.remove('_hidden');
        }
    }, [show])

    const handleClose = (e) => {
        e.preventDefault();

        toggle(!show);

        if(callbackClose){
            callbackClose();
        }

        dispatch(getSearchClear());

    } 

    return (
        <div className={`modal _default ${dopClass} ${show && '_active' || ''}`}>
            <div className="modal__bg" onClick={handleClose}></div>
            <div className="modal__wrap">
                <div className="modal__header">
                    {dopClass !== '_sale' && (
                        <a href="#" onClick={handleClose}>
                            <img src="https://cdn.podeli.ru/web-catalog/assets/img/icon-back.svg" />
                        </a>
                    )}
                    {header}
                </div>
                <div className="modal__content">
                    {content}
                </div>
                {footer && (
                    <div className="modal__footer">
                        {footer}
                    </div>
                )}
            </div>
        </div>
    )
}

export default Modal;