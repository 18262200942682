import React, { useEffect, useRef } from "react";

const FilterCheckbox = (props) => {

    const inputRef = useRef(null);

    const { title, value, name, checked, callback } = props;

    useEffect(() => {
        
        if(checked){
            inputRef.current.checked = checked;
        } else {
            inputRef.current.checked = false;
        }

    }, [checked])

    const handleChange = (e) => {
        callback(e);
    }

    return (
        <label className="checkbox _filter">
            <input ref={inputRef} className="checkbox__input" onChange={handleChange} type="checkbox" value={value} name={name} />
            <span className="checkbox__wrap">
                <span className="checkbox__title">{title}</span>
                <span className="checkbox__switche">
                    <span className="switche">
                        <span className="switche__bull"></span>
                    </span>
                </span>
            </span>
        </label>
    )
}

export default FilterCheckbox;