export const shops = {
    "Shops": [
      {
        "Title": "АльфаСтрахование",
        "Description": "Удобное оформление ОСАГО, МиниКАСКО, Страхования Ипотеки и полиса АнтиОНКО на сайте и в мобильном приложении.",
        "Subtitle": "Удобное страхование",
        "id": "40"
      },
      {
        "Title": "Starline",
        "Description": "НПО «СтарЛайн» — российский разработчик и производитель умного охранно-телематического оборудования для защиты автомобилей от угона.",
        "Subtitle": "Автосигнализации",
        "id": "64"
      },
      {
        "Title": "Лазерный Доктор",
        "Description": "Лазерный Доктор — сеть клиник эстетической медицины и косметологии: 13 клиник в Санкт-Петербурге и две в Москве. Все врачи имеют высшее медицинское образование и используют в работе только сертифицированные аппараты и препараты от производителей.\r\n\r\nДля оплаты с Подели вы получите прямую ссылку на оплату.",
        "Subtitle": "Эстетическая медицина",
        "id": "71"
      },
      {
        "Title": "Эйч",
        "Description": "Эйч — это сервис развития карьеры. 200+ экспертов из Apple, Google, Spotify, Яндекс, SETTERS и других ведущих компаний России и мира делятся опытом на индивидуальных карьерных консультациях. Эксперты Эйч помогают находить любимое дело, делать первые шаги в новой профессии, менять работу, строить карьеру за рубежом и расти в зарплате и навыках.\r\n\r\nДля оплаты с Подели вы получите прямую ссылку на оплату.",
        "Subtitle": "Сервис развития карьеры",
        "id": "54"
      },
      {
        "Title": "ЦВЕТЫ НА РАЙОНЕ",
        "Description": "ЦВЕТЫ НА РАЙОНЕ — сервис доставки букетов из свежих цветов по всей России и СНГ. Доставка от 60 минут.",
        "Subtitle": "Доставка по России и СНГ",
        "id": "53"
      },
      {
        "Title": "Alfina Boginya",
        "Description": "Alfina Boginya — интернет-магазин стильной и модной женской обуви фабричного качества.\r\n\r\nДля оплаты с Подели выберите «Оплата картами онлайн» в способах оплаты, а затем — «Подели на 4 платежа».",
        "Subtitle": "Обувь для богинь",
        "id": "74"
      },
      {
        "Title": "Zolla",
        "Description": "Zolla — бренд, который адаптирует мировые фешен-тренды под запросы российских покупателей. Zolla предлагает три коллекции, в которых представлена как базовая повседневная одежда, так и более смелые трендовые модели.",
        "Subtitle": "Мировые тренды и база",
        "id": "6"
      },
      {
        "Title": "Кукурента",
        "Description": "Российский аналог AirBnb, который ставит первостепенной задачей предоставить гостям выбор интересных квартир и домов, кемпингов и глэмпингов и безопасное бронирование с гарантированным заселением.",
        "Subtitle": "Российский аналог AirBnb",
        "id": "57"
      },
      {
        "Title": "REDMOND",
        "Description": "REDMOND — официальный интернет-магазин международного производителя бытовой техники. Большой выбор мультиварок, пароварок, мультипекарей, хлебопечек, мультикухонь и другой техники для кухни и дома.",
        "Subtitle": "Бытовая техника",
        "id": "65"
      },
      {
        "Title": "ЛЭТУАЛЬ",
        "Description": "ЛЭТУАЛЬ — сеть косметических магазинов, в которых представлено более 1 000 брендов, 200 из них — эксклюзивно.\r\n\r\nПокупка с Подели доступна в розничных магазинах и онлайн. Для оплаты отсканируйте QR-код в магазине, авторизуйтесь в сервисе и покажите кассиру индивидуальный QR-код клиента.\r\n\r\nС 11.05.2023 по 30.06.2023 в розничных магазинах ЛЭТУАЛЬ действует скидка 1 000 ₽ на одну покупку с Подели от 3 000 ₽. Подробные правила акции по ссылке.",
        "Subtitle": "1000+ брендов косметики",
        "id": "4"
      },
      {
        "Title": "Alfa Travel",
        "Description": "Путешествия за мили и деньги.",
        "Subtitle": "Оплата милями и деньгами\r\n",
        "id": "47"
      },
      {
        "Title": "Optika Optima",
        "Description": "Оптика «Оптима» — интернет-магазин контактных линз с доставкой",
        "Subtitle": "Очки и линзы",
        "id": "41"
      },
      {
        "Title": "ZARINA",
        "Description": "Просто быть женственной. Женственность ZARINA проявляется в лёгких и струящихся тканях, в утонченной одежде, изящных аксессуарах и деталях в отделке.",
        "Subtitle": "Трендовая классика",
        "id": "1"
      },
      {
        "Title": "Go! English Тамбов",
        "Description": "Go! English Тамбов — это не очередные курсы английского языка для всех возрастов.\r\n\r\nДля оплаты с Подели вы получите прямую ссылку на оплату.",
        "Subtitle": "Курсы для всех возрастов",
        "id": "45"
      },
      {
        "Title": "Detail",
        "Description": "Detail — бренд премиальной автохимии. Линейка Detail включает в себя весь спектр продуктов, необходимых для бережного ухода за интерьером и экстерьером автомобиля.",
        "Subtitle": "Премиальная автохимия",
        "id": "46"
      },
      {
        "Title": "QBoutique",
        "Description": "Всё для смарт-шопинга: позволяет сформировать полноценный гардероб, оставаясь в рамках разумного бюджета.",
        "Subtitle": "Всё для смарт-шопинга",
        "id": "48"
      },
      {
        "Title": "Pauline School",
        "Description": "Международная онлайн-школа кондитерского искусства.",
        "Subtitle": "Онлайн-школа кондитеров",
        "id": "60"
      },
      {
        "Title": "lio",
        "Description": "lio — маркетплейс локальных дизайнеров и брендов. Более 300 российских дизайнеров на одной платформе.",
        "Subtitle": "Локальные бренды",
        "id": "42"
      },
      {
        "Title": "Mass.Corporation",
        "Description": "Mass.Corporation — бренд базовой одежды для спорта и активного отдыха, которая производится в России. Большинство моделей свободного кроя, подходят и женщинам, и мужчинам.",
        "Subtitle": "Базовая одежда унисекс",
        "id": "43"
      },
      {
        "Title": "GetCourse ",
        "Description": "Онлайн-курсы от школ и авторов. Вы можете пройти обучение по разным направлениям: саморазвитие, дети, бизнес и карьера, спорт и многим другим.",
        "Subtitle": "Платформа онлайн-курсов",
        "id": "49"
      },
      {
        "Title": "Tigrr Market",
        "Description": "Тигрмаркет — интернет-магазин с широким ассортиментом и оперативной доставкой. Текстиль, товары для дома, декор, детские и спортивные товары, товары для питомцев, бытовая техника и многое другое.\r\n\r\nДля оплаты с Подели выберите «Оплата картой или Подели» в способах оплаты, а затем — «Подели на 4 платежа».",
        "Subtitle": "Онлайн-гиперррмаркет",
        "id": "97"
      },
      {
        "Title": "Sima-land",
        "Description": "Сима-ленд — крупный интернет-магазин и гипермаркет в Екатеринбурге с широким ассортиментом: от хозтоваров и игрушек до мебели. Оплата с Подели доступна как онлайн, так и офлайн.\r\n\r\nДля оплаты отсканируйте QR-код в магазине, авторизуйтесь в сервисе и покажите кассиру индивидуальный QR-код клиента.",
        "Subtitle": "Всё от игрушек до мебели",
        "id": "68"
      },
      {
        "Title": "GutenTech",
        "Description": "GutenTech — интернет-магазин крупной и мелкой бытовой техники от известных мировых брендов. Десятки категорий товаров для кухни, дома, красоты и здоровья.",
        "Subtitle": "Европейские бренды",
        "id": "70"
      },
      {
        "Title": "НОУ-ХАУ",
        "Description": "НОУ-ХАУ — интернет-магазин цифровой электроники.\r\n\r\nДля оплаты с Подели выберите «Онлайн картой» в способах оплаты, а затем — «Подели на 4 платежа».",
        "Subtitle": "Цифровая электроника",
        "id": "56"
      },
      {
        "Title": "Lex",
        "Description": "LEX производит высокотехнологичную, долговечную и доступную по цене технику при сохранении высоких гарантийных условий.\r\n",
        "Subtitle": "Техника для кухни",
        "id": "50"
      },
      {
        "Title": "АРТ-МАТИТА",
        "Description": "Онлайн-школа рисования.",
        "Subtitle": "Онлайн-школа рисования",
        "id": "58"
      },
      {
        "Title": "SHU",
        "Description": "SHU — бренд функциональной одежды, предметов для дома и базовых средств по уходу за кожей на каждый день.",
        "Subtitle": "База из Санкт-Петербурга",
        "id": "85"
      },
      {
        "Title": "Inmyroom",
        "Description": "Самая удобная платформа для поиска идей для дома и покупки мебели. Представлено больше 80 000 товаров для дома и интерьера от лучших российских и иностранных производителей.",
        "Subtitle": "Мебель и идеи для дома",
        "id": "81"
      },
      {
        "Title": "VITTORIA EDUCATION",
        "Description": "Образовательно-консалтинговая платформа обучения экспертов «Vittoria Education».\r\n\r\nОбучение экспертов позволяет эффективно монетизировать свои знания с помощью понятной методологии продукта.\r\n\r\nКоротко о платформе:\r\n— больше 50 учеников с результатом (от х3)\r\n— 1 месяц — среднее время окупаемости программы\r\n— 95% — средний показатель удовлетворенности клиентов\r\n— 12 дней — среднее время получения первого результата",
        "Subtitle": "Обучение экспертов",
        "id": "63"
      },
      {
        "Title": "ВоллиТолли",
        "Description": "WallyTally — интернет-магазин мягкой и корпусной мебели от производителя.",
        "Subtitle": "Мебель от производителя",
        "id": "100"
      },
      {
        "Title": "Furni",
        "Description": "Furni.ru — это не только интернет-магазин, торгующий импортными и российскими дизайнерскими предметами интерьера и мебелью, но и фабрика, на которой создают свои шедевры молодые дизайнеры мебели.",
        "Subtitle": "Дизайнерская мебель",
        "id": "93"
      },
      {
        "Title": "CaseGuru",
        "Description": "CaseGuru — интернет-магазин различных аксессуаров для смартфонов (чехлов, плёнок, зарядок, пауэрбанков) и техники для дома.",
        "Subtitle": "Те самые наушники",
        "id": "77"
      },
      {
        "Title": "Кассы.ру",
        "Description": "Kassy.ru — один из крупных билетных операторов, работающий в 122 городах России. На сайте компании пользователь может узнать актуальную информацию о культурно-развлекательных событиях своего города и подобрать для себя интересные мероприятия.",
        "Subtitle": "Билетный оператор",
        "id": "73"
      },
      {
        "Title": "Zelmer ",
        "Description": "Zelmer — интернет-магазин мелкой бытовой техники и товаров для красоты и здоровья.",
        "Subtitle": "Мелкая бытовая техника",
        "id": "35"
      },
      {
        "Title": "Школа Рукоделия ",
        "Description": "Комплексное обучение мастеров рукоделия. Учитесь не только шить или создавать украшения, но и продвигать свои работы в соцсетях, получая от хобби удовольствие и прибыль.\r\n\r\nДля оплаты с Подели выберите «Картой (Visa, Mastercard) через Альфа Банк» в способах оплаты, а затем — «Подели на 4 платежа».",
        "Subtitle": "Зарабатывайте на хобби",
        "id": "34"
      },
      {
        "Title": "Роза Хутор ",
        "Description": "Роза Хутор — круглогодичный горный курорт мирового класса.\r\n\r\nДля оплаты с Подели выберите «Оплата банковской картой» в способах оплаты, а затем — «Подели на 4 платежа».",
        "Subtitle": "Горный курорт в Сочи ",
        "id": "39"
      },
      {
        "Title": "Barmariska",
        "Description": "Barmariska — бренд яркой одежды для нескучной жизни. Одежда для тех, кто выбирает естественность, свободу движений и радость быть собой.",
        "Subtitle": "Яркая одежда для жизни",
        "id": "29"
      },
      {
        "Title": "Atova cosmetics",
        "Description": "Atova Cosmetics — интернет-магазин профессиональной уходовой косметики. Официальный представитель брендов Genosys, ZO Skin Health by Obagi, Shine PH, Hysqia, Eliocap и других.\r\n\r\nДля оплаты с Подели выберите «Оплата картой» в способах оплаты, а затем — «Подели на 4 платежа».",
        "Subtitle": "Уходовая профкосметика",
        "id": "31"
      },
      {
        "Title": "BRMSK",
        "Description": "BRMSK — это бренд одежды для девушек и женщин, которые бросают вызов условностям и стереотипам. BRMSK — это эмоции, характер и свобода. Свобода чувствовать. Свобода действовать. Свобода быть.",
        "Subtitle": "Одежда без стереотипов",
        "id": "33"
      },
      {
        "Title": "Тортомастер",
        "Description": "Тортомастер — всё для кондитера, товары для выпечки. 52 розничных магазина по по всей России и интернет-магазин с доставкой по всему миру.",
        "Subtitle": "Всё для кондитеров",
        "id": "94"
      },
      {
        "Title": "Акушерство",
        "Description": "Детский гипермаркет Акушерство.ру — это более 250 000 товаров для детей и родителей от более чем 4 000 популярных брендов. Обширный ассортимент магазина включает в себя всё, что может понадобиться родителям: начиная от товаров для беременных, пустышек, подгузников и заканчивая детской мебелью, велосипедами, автокреслами и колясками.",
        "Subtitle": "Детский гипермаркет",
        "id": "72"
      },
      {
        "Title": "BAITME",
        "Description": "BAITME — интернет-магазин стильных купальников.",
        "Subtitle": "Стильные купальники",
        "id": "118"
      },
      {
        "Title": "Nappyclub",
        "Description": "Настоящая экосистема необходимых товаров для ребёнка от 0 до 2 лет, созданная, опираясь на опыт мам и ведущих специалистов в области производства.",
        "Subtitle": "Товары для детей 0-2 лет",
        "id": "84"
      },
      {
        "Title": "Кант",
        "Description": "Кант специализируется на шести видах спорта: горные лыжи, сноуборд, беговые лыжи, туризм, роликовые коньки и велоспорт.\r\n\r\nПокупка с Подели доступна онлайн и в розничных магазинах. Для оплаты отсканируйте QR-код в магазине, авторизуйтесь в сервисе и покажите кассиру индивидуальный QR-код клиента.",
        "Subtitle": "Сноуборд, лыжи и туризм",
        "id": "109"
      },
      {
        "Title": "R’Clo",
        "Description": "R’Clo — интернет-магазин женской одежды.",
        "Subtitle": "Стиль нежного Нижнего",
        "id": "89"
      },
      {
        "Title": "Лента",
        "Description": "Лента — ведущая сеть многоформатных гипермаркетов по всей России, в которых можно найти всё: от продуктов до бытовой техники и автотоваров.\r\n\r\nДля оплаты отсканируйте QR-код в магазине, авторизуйтесь в сервисе и покажите кассиру индивидуальный QR-код клиента.\r\n\r\nСписок гипермаркетов, где доступна оплата с Подели [по ссылке](https://storage.yandexcloud.net/podeli.ru/landing_attachments/Lenta_store_addresses.pdf).",
        "Subtitle": "Сеть гипермаркетов",
        "id": "30"
      },
      {
        "Title": "CoolBoxBeauty",
        "Description": "Продлите жизнь своим любимым косметическим средствам с многофункциональными и стильными холодильниками CoolBoxBeauty. Они занимают мало места и впишутся в любой интерьер. Выберите свой холодильник для хранения и другие полезные гаджеты для ухода в интернет-магазине.\r\n\r\nДля оплаты с Подели выберите «Visa, Mastercard, МИР — через Альфа-Банк / Оплатить по частям с Подели» в способах оплаты, а затем — «Подели на 4 платежа».",
        "Subtitle": "Хранение косметики",
        "id": "122"
      },
      {
        "Title": "LELEYA",
        "Description": "LELEYA — российский бренд качественной женской одежды. Яркие современные образы подчёркивают индивидуальность и стиль каждой женщины.",
        "Subtitle": "Только натуральные ткани",
        "id": "80"
      },
      {
        "Title": "Funky Dunky",
        "Description": "Funky Dunky — один из лидеров рынка брендовой спортивной обуви и одежды. Ключевой партнёр мировых брендов Nike, Air Jordan, Puma, adidas и российских ZNY, Outlaw Moscow и др.",
        "Subtitle": "Мировые спортбренды",
        "id": "104"
      },
      {
        "Title": "Topcycler",
        "Description": "TopСycler — премиальная высокотехнологичная одежда для велоспорта из Швейцарии. В ассортименте бренда представлен широкий выбор не только велоодежды, но и аксессуаров.\r\n\r\nДля оплаты с Подели выберите «Онлайн оплата картой или по частям с Подели» в способах оплаты, а затем — «Подели на 4 платежа».",
        "Subtitle": "Одежда для велоспорта",
        "id": "95"
      },
      {
        "Title": "Мир матрасов",
        "Description": "«Мир Матрасов» — интернет-магазин, специализирующийся на продаже товаров для сна. Высококачественные матрасы, кровати, подушки и аксессуары, а также мебель для спальни популярных российских брендов: Орматек, Dream Line, Аскона, Corretto, Sleeptek, ВМК-Шале.",
        "Subtitle": "Брендовые товары для сна",
        "id": "86"
      },
      {
        "Title": "Look of you",
        "Description": "На одной площадке представлены отборные продукты для красоты изнутри, снаружи и вокруг.\r\n\r\nДля оплаты с Подели выберите «Безналичная оплата картой» в способах оплаты, а затем — «Подели».",
        "Subtitle": "Всё для вашей красоты",
        "id": "121"
      },
      {
        "Title": "Synergetic",
        "Description": "Современные и натуральные компоненты стали визитной карточкой SYNERGETIC. Чистота начинается с любви к дому, желания заботиться о себе и своих близких. В составах экопродуктов SYNERGETIC — только последние достижения науки для эффективного, безопасного и комфортного использования.",
        "Subtitle": "Экопродукты для дома",
        "id": "90"
      },
      {
        "Title": "Siberina",
        "Description": "SIBERINA — НАСТОЯЩАЯ НАТУРАЛЬНАЯ КОСМЕТИКА\r\n\r\nПрактически все продукты имеют 100% натуральный состав! Один из самых больших ассортиментов натуральной косметики среди всех мировых производителей настоящей натуральной косметики.",
        "Subtitle": "Натуральная косметика",
        "id": "128"
      },
      {
        "Title": "Ibeauty-russia",
        "Description": "Товары для бьюти-мастеров.",
        "Subtitle": "Всё для бьюти-мастера",
        "id": "125"
      },
      {
        "Title": "Regina Doctor",
        "Description": "Онлайн-школа здоровья, в команде которой только практикующие врачи с опытом лечебной работы.\r\n\r\nДля оплаты с Подели выберите «Оплата Подели» в способах оплаты, а затем — «Оплатить по частям».",
        "Subtitle": "Онлайн-школа здоровья",
        "id": "114"
      },
      {
        "Title": "Velcave",
        "Description": "Velcave — модная обувь и аксессуары для женщин и мужчин. Большой выбор известных брендов: Rieker, Тофа, Baden, Remonte, Berkonty и многих других.\r\n\r\nДля оплаты с Подели выберите «Подели или СБП» в способах оплаты, а затем — «Подели на 4 платежа».",
        "Subtitle": "Обувь известных брендов",
        "id": "92"
      },
      {
        "Title": "Parrey",
        "Description": "PARREY — это бренд мужской одежды в стиле лайфстайл-аутдор. Основа коллекций — удобная и качественная базовая одежда с современным дизайном, которая несёт простую идею — «Живи здесь и сейчас».",
        "Subtitle": "Удобная мужская одежда",
        "id": "99"
      },
      {
        "Title": "Procontact.dog ",
        "Description": "Онлайн-курсы по воспитанию послушных собак. Подходят для владельцев собак любого возраста и породы и для тех, кто только собирается завести собаку.\r\n\r\nДля оплаты с Подели выберите «Оплатить по частям» в способах оплаты.",
        "Subtitle": "Воспитание собак",
        "id": "123"
      },
      {
        "Title": "Русский букет",
        "Description": "Русский букет — сервис доставки цветов по Москве, России и миру. Вы легко найдёте поздравление для любого случая и сможете отправить его близким, где бы они не находились.",
        "Subtitle": "Доставка по РФ и миру",
        "id": "101"
      },
      {
        "Title": "Авиакасса",
        "Description": "Aviakassa — это современный, технологичный ресурс с удобной и очень простой навигацией. Основная цель проекта — предоставление пассажирам максимального набора услуг по организации путешествий авиа- и железнодорожным транспортом, страхование жизни и багажа во время путешествий, поиск и подбор туров и отелей, аренда автомобилей.",
        "Subtitle": "Билеты и бронь отелей",
        "id": "66"
      },
      {
        "Title": "LUGANG",
        "Description": "LUGANG — магазин качественной, удобной и стильной одежды от Алексея Долматова (рэпер Guf).",
        "Subtitle": "Одежда от Guf’a",
        "id": "112"
      },
      {
        "Title": "Button Blue",
        "Description": "Button Blue —розничная сеть, где представлена одежда для детей 3-12 лет холдинга Gulliver Group среднего ценового сегмента. В коллекции представлено несколько линеек: Casual Street Sport, Casual Outdoor, Active, Basic, Underwear. Вся одежда сшита из экологически чистых материалов без использования натурального меха и кожи животных.\r\n\r\nДля оплаты отсканируйте QR-код в магазине, авторизуйтесь в сервисе и покажите кассиру индивидуальный QR-код клиента.",
        "Subtitle": "Для детей от 3 до 12 лет",
        "id": "52"
      },
      {
        "Title": "Veloshop",
        "Description": "Интернет-магазин велосипедов, запчастей и аксессуаров российских и зарубежных производителей, таких как Stels, Merida, Cube, Forward, Trek, Stark, Giant и многих других.",
        "Subtitle": "Велосипеды и всё для них",
        "id": "113"
      },
      {
        "Title": "Gillette",
        "Description": "Интернет-магазин Gillette — всемирно известная компания-производитель аксессуаров для бритья. В ассортименте представлены бритвенные системы для мужчин и женщин.",
        "Subtitle": "Легендарные бритвы",
        "id": "117"
      },
      {
        "Title": "MIRUS",
        "Description": "Онлайн-обучение «С нуля до топ-мастера маникюра».\r\n\r\nДля оплаты с Подели выберите «Альтернативный способ оплаты частями» в списке способов оплаты, затем — «Оплатить с Подели».",
        "Subtitle": "Курсы мастеров маникюра",
        "id": "106"
      },
      {
        "Title": "SAVAGE",
        "Description": "SAVAGE — интернет-магазин успешного российского бренда с широким ассортиментом верхней (куртки, пальто и пуховики SAVAGE являются визитной карточкой бренда), легкой одежды и аксессуаров, представленных в коллекциях по принципу total look. Каждая из таких коллекций — это мини-гардероб со своим настроением и стилистикой, который предполагает возможность создания законченного образа в едином стиле.",
        "Subtitle": "Верхняя и лёгкая одежда",
        "id": "133"
      },
      {
        "Title": "ФК «Краснодар»",
        "Description": "Главный «чёрно-зелёный» интернет-магазин страны — лучшая возможность приобрести фирменную продукцию футбольного клуба «Краснодар».\r\n\r\nВ ассортименте представлена не только игровая и тренировочная форма ФК «Краснодар», но также различные виды летней и зимней спортивной и повседневной одежды для мужчин, женщин и детей, подарочная и сувенирная продукция с клубной символикой.",
        "Subtitle": "Фирменный мерч ФК",
        "id": "146"
      },
      {
        "Title": "Esthetic-nails",
        "Description": "Товары для бьюти-мастеров.",
        "Subtitle": "Всё для красоты",
        "id": "134"
      },
      {
        "Title": "KNT tricoté",
        "Description": "KNT tricoté — храм трикотажа, где представлена одежда в стиле тихого, интеллигентного люкса по разумным ценам.\r\n\r\nБренд использует материалы исключительного качества: мериносовая шерсть, итальянский хлопок, кашемир Loro Piana.",
        "Subtitle": "Тихая роскошь",
        "id": "127"
      },
      {
        "Title": "Mimi Land",
        "Description": "Мимилэнд — проект для развития у детей речи, мелкой моторики, логики и внимательности, интеллектуальных и творческих способностей.\r\n\r\nДля оплаты с Подели выберите «Онлайн оплата» в способах оплаты, а затем — «Подели».",
        "Subtitle": "Развитие детей",
        "id": "129"
      },
      {
        "Title": "NOVEX",
        "Description": "NOVEX — интернет-магазин и сеть розничных магазинов в Сибири с косметикой, парфюмерией, бытовой химией и товарами для дома. Здесь вы найдёте всё для ухода за собой и домашнего уюта.\r\n\r\nПокупка с Подели доступна онлайн и в розничных магазинах. Для оплаты отсканируйте QR-код в магазине, авторизуйтесь в сервисе и покажите кассиру индивидуальный QR-код клиента.",
        "Subtitle": "Уход за собой и домом",
        "id": "135"
      },
      {
        "Title": "Pilnikov",
        "Description": "Интернет-магазин головных уборов и шапок.",
        "Subtitle": "Шляпки и шапки",
        "id": "130"
      },
      {
        "Title": "Sprintmarket",
        "Description": "Sprintmarket.ru — интернет-магазин техники и электроники.\r\n\r\nДля оплаты с Подели выберите «Оплата картами VISA и Master Card на сайте / Оплата по частям с Подели» в способах оплаты, а затем — «Подели на 4 платежа».",
        "Subtitle": "Техника для офисов",
        "id": "38"
      },
      {
        "Title": "YOU",
        "Description": "YOU — бренд женской одежды из Санкт-Петербурга, полностью отражающий душу и дух северной столицы. В коллекциях бренда сочетается творческий подход к созданию одежды и северная практичность. В интернет-магазине представлен широкий ассортимент базовых вещей на каждый день, модных новинок и аксессуаров.",
        "Subtitle": "Шик Северной столицы",
        "id": "155"
      },
      {
        "Title": "Oxioma",
        "Description": "Интернет-магазин дизайнерских украшений.\r\n\r\nДля оплаты с Подели выберите «Оплата картой МИР, VISA, Master Card / Оплата по частям с Подели» в способах оплаты, а затем — «Подели на 4 платежа».",
        "Subtitle": "Дизайнерские украшения",
        "id": "157"
      },
      {
        "Title": "Phenomenalstudio",
        "Description": "Украшения ручной работы, которые с легкостью дополняют и создают неповторимый образ.\r\n\r\nДля оплаты с Подели выберите «Оплата частями без процентов» в способах оплаты, а затем — «Подели».",
        "Subtitle": "Ручная работа",
        "id": "153"
      },
      {
        "Title": "ELIT ",
        "Description": "ELIT — медицинский маркетплейс с доставкой по всей России. Всё для медицинских работников на одном сайте. Более 10 000 товаров и 100 продавцов. Не нужно посещать множество сайтов в поисках нужных товаров, всё можно найти на ELIT.",
        "Subtitle": "Медицинский маркетплейс",
        "id": "141"
      },
      {
        "Title": "Twofeet",
        "Description": "«TWO FEET» — это более 1 000 трендовых моделей мужской и женской обуви из натуральных материалов. Наши производители — это итальянские бренды, ставшие символами качества и хорошего вкуса.",
        "Subtitle": "Итальянская обувь",
        "id": "149"
      },
      {
        "Title": "Паче",
        "Description": "Паче — это бренд минималистичных купальников и белья, комфортных вещей для дома и отдыха.",
        "Subtitle": "Минималистичный шик",
        "id": "160"
      },
      {
        "Title": "Мам, купи!",
        "Description": "Авторская сувенирная продукция с уникальными принтами.",
        "Subtitle": "Ну смотри, какой мерч!",
        "id": "159"
      },
      {
        "Title": "LEOMAX",
        "Description": "LEOMAX — лидер на рынке телевизионной торговли России. Вы найдете более 15 собственных брендов одежды, обуви, товаров для кухни, запатентованных медицинских приборов и многое другое.\r\n\r\nДля оплаты с Подели выберите «Рассрочка Подели» в способах оплаты, а затем — «Подели».",
        "Subtitle": "Всё от одежды до техники",
        "id": "137"
      },
      {
        "Title": "Высшая лига",
        "Description": "TopLiga — это доступные цены, широкий, регулярно обновляемый ассортимент спортивных товаров. Кроссовки, футболки, ветровки, тайтсы, питание, куртки, ботинки и многое другое, всё, что позволит совершенствоваться в достижениях. На сайте представлены такие бренды, как Asics, Under Armour, Billabong, Quiksilver, Roxy и другие.",
        "Subtitle": "Одежда и обувь для бега",
        "id": "162"
      },
      {
        "Title": "1 Sort Shop 1",
        "Description": "Sort Shop 1",
        "id": "163"
      },
      {
        "Title": "Belle YOU",
        "Description": "Belle YOU — бренд базового нижнего белья и одежды для активного отдыха, спорта, повседневных занятий и домашнего досуга. Лаконичный дизайн, удобный крой и широкий размерный ряд — детали, которым уделяется особое внимание.",
        "Subtitle": "Лаконичное бельё",
        "id": "150"
      },
      {
        "Title": "4tochki",
        "Description": "Выбор покрышек — задача непростая. На сайте интернет-магазина 4tochki.ru существует удобная система подбора шин и дисков. На сайте представлены автомобильные шины и диски ведущих мировых производителей, что является гарантией высокого качества.",
        "Subtitle": "Колёса на любой радиус",
        "id": "36"
      },
      {
        "Title": "ABSOLUTSHOP",
        "Description": "ABSOLUTSHOP — мультибрендовый магазин одежды, обуви и аксессуаров. Представлены только оригинальные товары более 90 известных мировых брендов.",
        "Subtitle": "Мировые бренды",
        "id": "62"
      },
      {
        "Title": "Sneakerhead",
        "Description": "Sneakerhead — главный кроссовочный магазин России, в ассортименте которого представлены не только кроссовки, но и одежда и аксессуары.",
        "Subtitle": "Главный по кроссам",
        "id": "152"
      },
      {
        "Title": "FINN FLARE",
        "Description": "FINN FLARE — это лаконичная одежда в скандинавском стиле. Основа коллекций — комфортная и практичная база, разбавленная актуальными модными трендами.",
        "Subtitle": "Скандинавский стиль",
        "id": "144"
      },
      {
        "Title": "Sohoshop",
        "Description": "На одной площадке объединены лучшие торговые марки из Европы, США и Австралии. Только оригинальные товары известных производителей.\r\n\r\nПокупка с Подели доступна онлайн и в розничных магазинах сети SOHO. Для оплаты отсканируйте QR-код в магазине, авторизуйтесь в сервисе и покажите кассиру индивидуальный QR-код клиента.",
        "Subtitle": "Атмосфера Нью-Йорка",
        "id": "158"
      },
      {
        "Title": "1001 Dress",
        "Description": "Интернет-магазин 1001DRESS — это вечерние платья для особого случая, деловые образы в офис, варианты для прогулок на каждый день.",
        "Subtitle": "Платья для любого случая",
        "id": "124"
      },
      {
        "Title": "Kokosina",
        "Description": "Основу ассортимента составляют городские кожаные рюкзаки, которые освободили руки и разгрузили плечи тысячам девушек и парней по всему миру.",
        "Subtitle": "Городские рюкзаки",
        "id": "156"
      },
      {
        "Title": "2 Sort Shop 2",
        "Description": "2 Sort Shop 2",
        "id": "164"
      },
      {
        "Title": "LAVANT fashion",
        "Description": "Интернет-магазин женской одежды премиум-класса с доставкой по РФ.",
        "Subtitle": "Одежда премиум-класса",
        "id": "154"
      },
      {
        "Title": "Сила ветра",
        "Description": "Сила ветра — это энтузиасты, которые открывают для себя и всех вокруг приключения под парусом и обучение яхтингу. А ещё создают необходимое снаряжение, стильную яхтенную одежду и полезные аксессуары для прибрежной городской жизни.",
        "Subtitle": "Одежда для яхтинга",
        "id": "142"
      },
      {
        "Title": "NOVATEX",
        "Description": "Одежда для сильных духом, активных и требовательных людей, которые знают, чего хотят. Неважно, какая погода за окном: зимние холода, проливные дожди или жара. С экипировкой NOVATEX вы забудете о ней и будете наслаждаться охотой, рыбной ловлей или покорением нового туристического маршрута.",
        "Subtitle": "Для активного отдыха",
        "id": "140"
      },
      {
        "Title": "Gloria Jeans",
        "Description": "Gloria Jeans — бренд одежды, который не нуждается в представлении. В магазинах сети можно подобрать гардероб для всей семьи: взрослых, подростков и детей от года. Особое место в ассортименте GJ занимает линейка из денима — те самые джинсы, куртки и шорты из качественной и долговечной ткани.\r\n\r\nДля оплаты отсканируйте QR-код в магазине, авторизуйтесь в сервисе и покажите кассиру индивидуальный QR-код клиента.",
        "Subtitle": "Деним и не только",
        "id": "7"
      },
      {
        "Title": "DEVITO-MARKET",
        "Description": "DEVITO-MARKET — интернет-гипермаркет, в котором собрано более 500 000 товаров: от аксессуаров и детских игрушек до подарков и автомобильных дисков.",
        "Subtitle": "Интернет-гипермаркет",
        "id": "108"
      },
      {
        "Title": "FlyCloud Shop",
        "Description": "FLYCLOUD SHOP — магазин минималистичных ювелирных украшений, товаров для дома и одежды на каждый день.\r\n\r\nДля оплаты с Подели нажмите «Оформить заказ», а затем выберите «Подели на 4 платежа».",
        "Subtitle": "Современная классика",
        "id": "138"
      },
      {
        "Title": "DAISYKNIT",
        "Description": "DAISYKNIT — это семейная мануфактура из Сибири, создающая одежду из натуральных итальянских материалов для современных активных девушек.",
        "Subtitle": "Семейная мануфактура",
        "id": "143"
      },
      {
        "Title": "Braun",
        "Description": "Компания Braun — всемирно известный немецкий бренд, который производит высококачественную технику премиум-класса. Продукция Braun известна своей надежностью и долговечностью, что снискало марке особое доверие.",
        "Subtitle": "Немецкая техника",
        "id": "59"
      },
      {
        "Title": "Anywayanyday",
        "Description": "Anywayanyday — крупнейший в России онлайн-сервис по покупке авиабилетов.\r\n\r\n⁃ 3 минуты — среднее время, которое уходит на поиск, выбор и покупку авиабилета на anywayanyday.com\r\n\r\n⁃ 200 вариантов перелета в секунду обрабатывается по каждому запросу\r\n⁃ Бонусная программа: до 1% за покупку авиабилетов. Оплата накопленными баллами: 1 балл = 1 рубль",
        "Subtitle": "Авиабилеты онлайн",
        "id": "37"
      },
      {
        "Title": "Befree",
        "Description": "Befree создан для молодых и модных мечтателей, предпочитающих покупать одежду по доступной цене.",
        "Subtitle": "Для молодых мечтателей",
        "id": "151"
      },
      {
        "Title": "РИВ ГОШ",
        "Description": "РИВ ГОШ — один из крупнейших российских магазинов косметики и парфюмерии. В сети представлена косметика мировых брендов во всех ценовых сегментах — от масс-маркета до люкса.\r\n\r\nПокупка с Подели доступна в розничных магазинах и онлайн. Для оплаты отсканируйте QR-код в магазине, авторизуйтесь в сервисе и покажите кассиру индивидуальный QR-код клиента.\r\n\r\nС 07.06.2023 по 30.06.2023 в интернет-магазине РИВ ГОШ действует дополнительная скидка 10% на покупки с Подели. Подробные правила акции по ссылке.\r\n\r\nС 01.06.2023 по 15.06.2023 в розничных магазинах РИВ ГОШ действует скидка 10% на одну покупку с Подели, максимальный размер скидки — 1 000 ₽. Подробные правила акции по ссылке.",
        "Subtitle": "От масс-маркета до люкса",
        "id": "5"
      },
      {
        "Title": "MP LAB ",
        "Description": "MP LAB — сервис для автоматизации продаж на Wildberries. Продвижение через выкупы с автоматизированной оплатой, вывод в топ по ключевым SEO-запросам, юридические услуги и подбор товаров из Китая.\r\n",
        "Subtitle": "Автоматизация продаж",
        "id": "44"
      },
      {
        "Title": "Littlestar",
        "Description": "Интернет-магазин Littlestar — это более 2 000 товаров высочайшего качества.\r\n\r\nВ магазине представлена линейка трикотажной и вязаной одежды собственных брендов Little Star и Ниточки-Клубочки.\r\n\r\nРазмерный ряд магазина включает в себя одежду и аксессуары для мальчиков и девочек от 0 до 6 лет.",
        "Subtitle": "Вязаная одежда для детей",
        "id": "28"
      },
      {
        "Title": "Библиотека ароматов",
        "Description": "biblioteka.shop — онлайн-магазин парфюмерии брендов Demeter, biblioteka aromatov и Fragrance Community.\r\n\r\nУ каждого должна быть возможность пользоваться классными ароматами. А сама парфюмерия должна быть увлекательной, понятной и доступной. «Трава», «Ванильное мороженое», «Лунная пыль» и ещё более 1 000 уникальных ароматов — найдите свой!",
        "Subtitle": "Уникальный парфюм",
        "id": "161"
      }
    ]
  }