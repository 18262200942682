import React, { useRef, useState, useEffect } from "react";

// import './style.css';

const SearchForm = (props) => {

    const { callback, valueSearch, setInputOutRef, callbackReset } = props;

    const inputRef = useRef(null);

    const [value, setValue] = useState('');

    useEffect(() => {
        // if(valueSearch){
            setValue(valueSearch);
        // }
    }, [valueSearch]);

    useEffect(() => {
        if(inputRef && setInputOutRef){
            setInputOutRef(inputRef);
        }
    }, [inputRef])

    useEffect(() => {

        if(callback){

            callback(value);   

        }

    }, [value])

    const handleChange = (e) => {

        setValue(e.target.value);

    }

    const handleReset = (e) => {
        e.preventDefault();

        setValue('');

        if(callbackReset){
            callbackReset();
        }

    }

    return (
        <div className='search'>
            <input ref={inputRef} type="text" className='search__input' value={value} onChange={handleChange} placeholder='Поиск'/>
            {value !== '' && (
                <a href="#" className='search__reset' onClick={handleReset}>
                    <img src="https://cdn.podeli.ru/web-catalog/assets/img/icon-reset-search.svg" />
                </a>
            )}
        </div>
    )

}

export default SearchForm;