import {createActions} from "redux-actions";

const {
    getSearch,
    getSearchSuccess,
    getSearchFailure,
    getSearchIdle,
    getSearchClear
} = createActions(
    "GET_SEARCH",
    "GET_SEARCH_SUCCESS",
    "GET_SEARCH_FAILURE",
    "GET_SEARCH_IDLE",
    "GET_SEARCH_CLEAR"
);


export {
    getSearch,
    getSearchSuccess,
    getSearchFailure,
    getSearchIdle,
    getSearchClear
};
