import {call, put, takeEvery, all, takeLatest} from "redux-saga/effects";

import { getReguest } from '../initData/api';

import {
    getCategory,
    getCategorySuccess,
    getCategoryFailure,
    getCategoryIdle,
} from "./actions";

function* getCategorySaga(action) {
    try {

        let { payload } = action;

        const { id, currentCity, topShops } = payload; 

        let idsTopShops = [];
        let idsNotTopShops = [];


        if(topShops && topShops.data && topShops.data.length > 0){
            topShops.data.map((item, index) => {
                idsTopShops.push('filters[id][$in]['+index+']='+item.id);
                // idsNotTopShops.push('filters[id][$notIn]['+index+']='+item.id)
            })
        }

        // "populate[Top_shops][fields][0]=Title" +
        //             "&populate[Top_shops][fields][1]=Subtitle" +
        //             "&populate[Top_shops][populate]=Preview,Preview_list,Logo,Sort" +
        //             "&populate[Top_shops][populate]=Sale.Sale_card" +
        //             "&populate[Top_shops][populate]=Sale_card"

        // let populate = 'populate[Preview][populate]=*'+
        //     '&populate[Categories][populate]=*'+
        //     '&populate[Logo][populate]=*'+
        //     '&populate[Preview_list][populate]=*'+
        //     '&populate[Sale][populate]=Sale_card'+
        //     '&populate[Sale_card][populate]=*'+
        //     '&populate[Sale_alpha][populate]=Alpha_card'+
        //     '&populate[Alpha_card][populate]=*'+
        //     '&populate[Sort_Component][populate]=*';
        let populate = 'fields[0]=Title';
        populate = populate + '&fields[1]=Subtitle';
        populate = populate + '&fields[2]=Show_percent_on_preview';
        populate = populate + "&populate=Preview,Preview_list,Logo,Sort";
        populate = populate + "&populate=Sale.Sale_card";
        populate = populate + "&populate=Sale_card";
        populate = populate + "&populate=Shops";
        // populate = populate + "&populate[Shops]=Sale_card";



        let routeTopShops = "/api/shops/?" + idsTopShops.join('&') + '&' + populate + '&pagination[pageSize]=1000';
        // let routeShops = "/api/shops/?" + idsNotTopShops.join('&') + "&filters[Categories][id][$in]=" + id + "&" + populate + "&pagination[pageSize]=1000";
        let routeShops = "/api/shops/?filters[Categories][id][$in]=" + id + "&" + populate + "&pagination[pageSize]=1000";
        let routeSales = "/api/sales/?sort=Sort&filters[Categories][id][$in]=" + id + "&populate[Sale_card][populate]=*";

        if(currentCity && currentCity.id !== 'all'){
            routeShops = routeShops + '&filters[Cities][id][$in]=' + currentCity.id;
        }

        let shopsAll = [];

        const [
            category,
            shops,
            sales,
            // topShopsResult
        ] = yield all([
            call(getReguest, {  route: "/api/categories/" + id + "?populate[Shops][populate]=Preview,Preview_list,Logo,Sale_card"}),
            call(getReguest, { route: routeShops }),
            call(getReguest, { route: routeSales }),
            // call(getReguest, { route: routeTopShops })
        ]);


        // if(shops && shops.data && shops.data.length > 0){
        //     shops.data.map(item => {
        //         // let foundItem = topShopsResult.data.filter(el => el.id == item.id);
        //         // if(foundItem && foundItem.length > 0){
        //             shopsAll.push(item);
        //         // }
        //     })
        // }

        if(category && category.data.attributes.Shops && category.data.attributes.Shops.data && category.data.attributes.Shops.data.length > 0){
            shopsAll = [
                // ...shopsAll,
                ...category.data.attributes.Shops.data
            ]
        }


        yield put(getCategorySuccess({
            shops: shopsAll,
            sales
        }));

    } catch (error) {

        yield put(getCategoryFailure(error));

    }
}


export function* getCategoryWatcher() {
    yield takeLatest(getCategory.toString(), getCategorySaga);
}
