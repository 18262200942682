import {
  call,
  put,
  takeEvery,
  all,
  takeLatest,
  select,
} from "redux-saga/effects";

import { getReguest } from "../initData/api";

import {
  getShopsByFilter,
  getShopsByFilterSuccess,
  getShopsByFilterFailure,
  getShopsByFilterIdle,
} from "./actions";

import { filterCategory } from "../initData/selector";

function* getShopsByFilterSaga(action) {
  try {
    let { payload } = action;

    let { currentCity, activesTypes, activesCategory, categoriesById } =
      payload;

    const categories = yield select(filterCategory);

    let routeDefault = "/api/shops?";

    // let routeDefault = '/api/shops?'+
    //     'populate=*'
    // 'populate[Store_types][populate]=*'+
    // '&populate[Categories][populate]=*'+
    // '&populate[Logo][populate]=*'+
    // '&populate[Preview][populate]=*'+
    // '&populate[0]=Sale'+
    // '&populate[1]=Sale.Sale_card'+
    // '&populate[2]=Sale.Sale_card.Percent'+
    // '&populate[Sale][populate]=Sale.Sale_card.Percent'+
    // '&populate[Sale_card][populate]=*'+
    // '&populate[Sale_alpha][populate]=*'+
    // '&populate[Alpha_card][populate]=*'+
    // '&populate[Sale_alpha][populate]=Alpha_card.Preview'+
    // '&populate[Sale_alpha][populate]=Alpha_card.Title_on_gray_group'+
    // '&populate[Sale_alpha][populate]=Alpha_card.SubTitle_on_gray_group';
    //- "populate[Categories][populate]=*"+
    //- "&populate[Logo][populate]=*"+
    //- "&populate[Preview_list][populate]=*"+
    //- "&populate[Preview][populate]=*"+
    //- "&populate[Sale][populate]=Sale"+
    //- "&populate[Sale_card][populate]=*";

    if (activesTypes && activesTypes.length > 0) {
      // 1 Интернет магазин
      // 2 Розничный магазин
      activesTypes.map((id, index) => {
        // routeDefault = routeDefault + '&';
        if (id == 1) {
          routeDefault = routeDefault + 'filters[Web_store][$notContains]=""&';
        }
        if (id == 2) {
          routeDefault =
            routeDefault + 'filters[Retail_store][$notContains]=""&';
        }
        // if(activesTypes.length == index + 1){
        //     routeDefault = routeDefault + 'filters[Store_types][id][$in]=' + id;
        // } else {
        //     routeDefault = routeDefault + 'filters[Store_types][id][$in]=' + id + "&";
        // }
      });
    }

    if (currentCity && currentCity.id !== "all") {
      routeDefault =
        routeDefault + "&filters[Cities][id][$in]=" + currentCity.id;
    }

    let categoriesIds = [];

    if (activesCategory && activesCategory.length > 0) {
      activesCategory.map((id) => {
        categoriesIds.push(id);
      });
    } else {
      if (categories && categories.length > 0) {
        categories.map((item) => {
          categoriesIds.push(item.id);
        });
      }
    }

    // if(topShops && topShops.data && topShops.data.length > 0){
    //     topShops.data.map((item, index) => {
    //         idsTopShops.push('filters[id][$in]['+index+']='+item.id);
    //         idsNotTopShops.push('filters[id][$notIn]['+index+']='+item.id)
    //     })
    // }

    let shopsByFilter = yield all(
      categoriesIds.map((id, index) => {
        // let countPosts = categories[index].attributes.Count_posts_homepage && categories[index].attributes.Count_posts_homepage || 10;

        let route = routeDefault + "&filters[Categories][id][$in]=" + id;

        route = route + "&pagination[pageSize]=100";

        return call(getReguest, { route, id });

        // route = route + '&populate[Store_types][populate]=*'+
        //     '&populate[Categories][populate]=*'+
        //     '&populate[Logo][populate]=*'+
        //     '&populate[Preview][populate]=*'+
        //     '&populate[Sale][populate]=*'+
        //     '&populate[Sale][Sale_card][populate]=*'+
        //     '&populate[Sale_card][populate]=*'+
        //     '&populate[Sale_alpha][populate]=*'+
        //     '&populate[Alpha_card][populate]=*'+
        //     '&populate[Sale_alpha][populate]=Alpha_card.Preview'+
        //     '&populate[Sale_alpha][populate]=Alpha_card.Title_on_gray_group'+
        //     '&populate[Sale_alpha][populate]=Alpha_card.SubTitle_on_gray_group';
      })
    );

    console.log(shopsByFilter);

    yield put(getShopsByFilterSuccess(shopsByFilter));
  } catch (error) {
    yield put(getShopsByFilterFailure(error));
  }
}

export function* getShopsByFilterWatcher() {
  yield takeLatest(getShopsByFilter.toString(), getShopsByFilterSaga);
}
