import {handleActions} from "redux-actions";
import {combineReducers} from "redux";
import {
    getSearch,
    getSearchSuccess,
    getSearchFailure,
    getSearchIdle,
    getSearchClear
} from "./actions";

const status = handleActions(
    {
        [getSearchSuccess]: () => "SUCCESS",
        [getSearch]: () => "LOADING",
        [getSearchFailure]: () => "FAILURE",
        [getSearchIdle]: () => "IDLE",
    },
    'IDLE'
);

const message = handleActions(
    {
        [getSearchSuccess]: (state, action) => {
            if(action.payload.data && !action.payload.data.length){
                return 'not-found'
            }
        },
        [getSearch]: () => "Идет поиск",
        [getSearchClear]: () => ''
    },
    'Идет поиск'
);

const shops = handleActions(
    {
        [getSearchSuccess]: (state, action) => action.payload.data,
        [getSearchClear]: (state, action) => null
    },
    null
)

const search = combineReducers({
    status,
    shops,
    message
});

export {search};
