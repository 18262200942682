import {call, put, takeEvery, all, takeLatest} from "redux-saga/effects";

import { getReguest } from '../initData/api';

import {
    getSearch,
    getSearchSuccess,
    getSearchFailure,
    getSearchIdle,
} from "./actions";

function* getSearchSaga(action) {
    // Test 2
    try {

        let { payload } = action;

        const { searchValue } = payload; 

        let populate = 
            "fields[0]=Title" +
            "&fields[1]=Subtitle" +
            "&fields[2]=Show_percent_on_preview" +
            "&fields[3]=Web_store" +
            "&fields[4]=Retail_store" +
            '&populate[Preview][populate]=*'+
            '&populate[Categories][populate]=*'+
            '&populate[Logo][populate]=*'+
            '&populate[Preview_list][populate]=*'+
            '&populate[Sale][populate]=Sale_card'+
            '&populate[Sale_card][populate]=*'+
            '&populate[Sale_alpha][populate]=Alpha_card'+
            '&populate[Alpha_card][populate]=*';
            // '&populate[Sale_alpha][populate]=*'+
            // '&populate[Alpha_card][populate]=*'+
            // '&populate[Sale_alpha][populate]=Alpha_card.Preview'+
            // '&populate[Sale_alpha][populate]=Alpha_card.Title_on_gray_group'+
            // '&populate[Sale_alpha][populate]=Alpha_card.SubTitle_on_gray_group';

        let route = "/api/shops?"+populate+"&filters[$or][0][Keywords][$containsi]=" + searchValue + "&filters[$or][1][Title][$containsi]=" + searchValue + "&";

        if(searchValue && searchValue.split(' ').length > 1){
            route = '/api/shops?';

            let iEven = -2;
            let iOdd = -1;

            searchValue.split(' ').map((item, index) => {
                iEven = iEven + 2;
                iOdd = iOdd + 2;
                if(item.length > 0 && item !== ''){
                    route = route + "filters[$or]["+iEven+"][Keywords][$containsi]=" + item + "&filters[$or]["+iOdd+"][Title][$containsi]=" + item + "&";
                }
            })
        }

        route = route + '&pagination[pageSize]=100';

        // route = route + "&populate[Preview]=*"+
        //     "&populate[Logo]=*"+
        //     "&populate[Preview_list]=*"+
        //     "&populate[Sale]=*"+
        //     "&populate[Sale_card]=*"+
        //     "&populate[Alpha_card]=*"+
        //     "&populate[Sale_alpha]=*";

        const [
            search
        ] = yield all([
            call(getReguest, { route: route }),
        ]);


        yield put(getSearchSuccess(search));

    } catch (error) {

        yield put(getSearchFailure(error));

    }
}


export function* getSearchWatcher() {
    yield takeLatest(getSearch.toString(), getSearchSaga);
}
