import {call, put, takeEvery, all, takeLatest} from "redux-saga/effects";

import { getReguest } from '../initData/api';

import {
    getSales,
    getSalesSuccess,
    getSalesFailure,
    getSalesIdle,
} from "./actions";

function* getSalesSaga(action) {
    try {

        const { payload } = action;

        let route = "/api/sales/?sort=Sort&populate[Sale_card][populate]=*";

        if(payload && payload.id){
            route = route + '&filters[Categories][id][$in]=' + payload.id;
        }

        route = route + '&pagination[pageSize]=100';

        const [
            sales
        ] = yield all([
            call(getReguest, { route: route }),
        ]);

        console.log(sales);

        yield put(getSalesSuccess(sales));

    } catch (error) {

        yield put(getSalesFailure(error));

    }
}


export function* getSalesWatcher() {
    yield takeLatest(getSales.toString(), getSalesSaga);
}
