import {createActions} from "redux-actions";

const {
    addShop,
    addShopSuccess,
    addShopFailure,
    addShopIdle,
    updateShop,
    updateShopSuccess,
    updateShopFailure,
    updateShopIdle,
} = createActions(
    "ADD_SHOP",
    "ADD_SHOP_SUCCESS",
    "ADD_SHOP_FAILURE",
    "ADD_SHOP_IDLE",
    "UPDATE_SHOP",
    "UPDATE_SHOP_SUCCESS",
    "UPDATE_SHOP_FAILURE",
    "UPDATE_SHOP_IDLE",
);


export {
    addShop,
    addShopSuccess,
    addShopFailure,
    addShopIdle,
    updateShop,
    updateShopSuccess,
    updateShopFailure,
    updateShopIdle,
};
