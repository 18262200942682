import {call, put, takeEvery, all, takeLatest} from "redux-saga/effects";

import { getReguest } from '../initData/api';

import {
    getSale,
    getSaleSuccess,
    getSaleFailure,
    getSaleIdle,
} from "./actions";

function* getSaleSaga(action) {
    try {

        let { payload } = action;

        const { id } = payload; 

        const [
            sale
        ] = yield all([
            call(getReguest, { route: "/api/sales/" + id + "/?populate[Sale_card][populate]=*" }),
        ]);

        yield put(getSaleSuccess(sale));

    } catch (error) {

        yield put(getSaleFailure(error));

    }
}


export function* getSaleWatcher() {
    yield takeLatest(getSale.toString(), getSaleSaga);
}
