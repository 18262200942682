import React, { useEffect, useState, useCallback } from 'react';

// import './style.css'

import { useSelector, useDispatch } from 'react-redux';

import { Link } from "react-router-dom";


import Filter from '../../layout/filter';

import SearchForm from "../../components/searchForm";
import PostSale from "../../components/postSale";
import PostShop from "../../components/postShop";

import CategoryCheckbox from './components/categoryCheckbox';

import { setFilterCategory } from '../../modules/filter/actions';
import { setSearch } from '../../modules/initData/actions';
import { getSearch, getSearchIdle } from "../../modules/search/actions";
import { getUrlImage, targetMetrick } from '../../constants/config';
import { selectorShopsByFilter } from '../../modules/shopsByFilter/selector';
import { getShopsByFilter } from '../../modules/shopsByFilter/actions';
import UserMini from './components/user/userMini';
import Loader from '../../components/loader';

const Home = (props) => {

    const { 
        showCities, 
        setShowCities,
        showFilter, 
        setShowFilter,
        showSearch,
        setShowSearch,
        inputSearchRef,
        setValueSearch,
        valueSearch,
        valueSearchHome, 
        setValueSearchHome
    } = props;

    // useEffect(() => {

        // if(inputSearchRef && inputSearchRef.current){
            // setValueSearch(valueSearchHome);
            // setValueSearchHome(valueSearchHome);
        // }

        // if(valueSearch.length > 2){
                                
            // dispatch(setSearch(valueSearchHome));

            // setTimeout(function(){
                
            // }, 1000);

            // setShowSearch(!showSearch);

            // setTimeout(function(){
                // inputSearchRef.current.focus();
            //     inputSearchRef.current.value = valueSearchHome;
            // }, 100);

    //     }
    // }, [valueSearch])

    const dispatch = useDispatch();

    const statusInitData = useSelector(state => state.initData.status);

    const currentCity = useSelector(state => state.initData.city);
    const filterCategory = useSelector(state => state.initData.categories);
    const categoriesById = useSelector(state => state.initData.categoriesById);
    const categoriesSortById = useSelector(state => state.initData.categoriesSortById);
    const filterCategoryWithItems = useSelector(state => state.initData.categoriesWithItemsIds);
    const activesCategory = useSelector(state => state.filter.activesCategory);
    const activesTypes = useSelector(state => state.filter.activesTypes);

    const topSales = useSelector(state => state.initData.topSales);
    const topAlpha = useSelector(state => state.initData.topAlpha);

    const allSalesCount = useSelector(state => state.initData.allSalesCount);
    const allSalesAlphaCount = useSelector(state => state.initData.allSalesAlphaCount);

    const shopsByFilter = useSelector(selectorShopsByFilter);

    const statusShopsByFilter = useSelector(state => state.shopsByFilter.status);
    
    useEffect(() => {
        if(statusShopsByFilter == 'IDLE' && statusInitData == 'SUCCESS'){
            dispatch(getShopsByFilter({
                currentCity, 
                activesTypes, 
                activesCategory,
                categoriesById
            }));
        }
    }, [statusInitData])

    const handleShowCities = (e) => {
        e.preventDefault();

        setShowCities(!showCities);
        
    }

    const handleShowFilter = (e) => {
        // e.preventDefault();

        setShowFilter(!showFilter);

        targetMetrick('Открытие/закрытие фильтра');
        
    }

    const handleChangeFilter = (e, type) => {
        let checkedFilter = activesCategory;

        targetMetrick()

        if(e.target.checked){
            checkedFilter = [
                ...checkedFilter,
                parseInt(e.target.value)
            ]
        } else {
            const indexFilterItem = checkedFilter.indexOf(parseInt(e.target.value));
            if (indexFilterItem > -1) {
                checkedFilter.splice(indexFilterItem, 1);
            }
        }

        if(statusShopsByFilter == 'IDLE'){
            dispatch(getShopsByFilter({
                currentCity, 
                activesTypes, 
                activesCategory: checkedFilter,
                categoriesById
            }))
        }

        dispatch(setFilterCategory(checkedFilter));

    }

    return (
        <div className="page _home">
            <div className='page__wrap'>
                {/* <div className='page__select-city'>
                    <a href="#" onClick={handleShowCities}>{currentCity.Title}</a>
                </div> */}
                <div className='page__hero'>
                    <div className='page__header'>
                        <div className='page__title'>Магазины</div>
                        <div className='page__user'>
                            {/* <UserMini /> */}
                        </div>
                    </div>
                    <div className='page__search'>
                        <SearchForm
                            valueSearch={valueSearchHome} 
                            callback={(value) => {
                                if(value){
                                    // setValueSearch(value);
                                    setValueSearchHome(value);
                                    setValueSearch(value);
                                    if(value.length > 0){
                                        setShowSearch(!showSearch);
                                        inputSearchRef.current.focus();
                                    }
                                }
                                
                                // setValueSearchHome(value);

                            }}                         
                        />
                    </div>
                </div>
                <div className='page__category'>
                    <div className='category _default _sale'>
                        <div className='category__header'>
                            <div className='category__title'>
                                <Link to={`/sales`}>Акции</Link>
                            </div>
                            {allSalesCount && <Link to={`/sales`} className='category__link'>Все {allSalesCount}</Link>}
                        </div>
                        <div className='category__list'>
                            {topAlpha && topAlpha.length > 0 && topAlpha.map((item, index) => {

                                const { attributes } = item;

                                const { Alpha_card } = attributes;

                                const { Link, Title_on_card, Preview, Description_on_card } = Alpha_card;

                                return (
                                    <div className='category__list-item' key={item.id}>
                                        <PostSale 
                                            type={'top'} 
                                            link={Link}
                                            preview={getUrlImage(Preview)} 
                                            title={Title_on_card}
                                            description={Description_on_card}
                                            attributes={attributes}
                                        />
                                    </div>
                                )
                            })}
                            {topSales && topSales.length > 0 && topSales.map((item, index) => {

                                const { id, attributes } = item;

                                const { Sale_card, YandexTarget, Show_percent_on_home } = attributes;
                                if (!Sale_card) {
                                    return;
                                }
                                const { Percent, Preview, Logo, Percent_symbol, Percent_Symbol_Before } = Sale_card;

                                if(!Preview || Preview && !Preview.data){
                                    return;
                                }

                                return (
                                    <div className='category__list-item' key={item.id}>
                                        <PostSale  
                                            id={id}
                                            preview={getUrlImage(Preview)} 
                                            logo={getUrlImage(Logo)}
                                            price={Show_percent_on_home && Percent && ( Percent_Symbol_Before && Percent_Symbol_Before + ' ' || '-' ) + Percent + ( Percent_symbol && Percent_symbol || '%') || null}
                                            target={YandexTarget}
                                            // attributes={attributes}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className='page__filter'>
                    <div className={`filter-list _desktop ${showFilter ? '_show' : ''}`}>
                        <button className='button filter-list__close' onClick={e => setShowFilter(false)}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.34302 17.6568L11.9999 12M11.9999 12L17.6567 6.34313M11.9999 12L17.6567 17.6568M11.9999 12L6.34302 6.34313" stroke="black" stroke-width="2" stroke-linecap="round"/>
                            </svg>
                        </button>
                        <div className='filter-list__title'>Фильтр:</div>
                        <div className='filter-list__wrap'>
                            <Filter type="inline" showFilter={showFilter} setShowFilter={setShowFilter} />
                        </div>
                    </div>
                    <div className='filter _category'>
                        <button className='filter__toggle' onClick={handleShowFilter}>
                            <img alt="toggle" src="https://cdn.podeli.ru/web-catalog/assets/img/icon-filter-toggle.svg" />
                        </button>
                        <div className='filter__list'>
                            {categoriesSortById && categoriesSortById.map((id, index) => {
                                let item = categoriesById[id];

                                // console.log(activesCategory, id);
                                if(id == 13 || id == 14){
                                    return true;
                                }

                                return (
                                    <div className='filter__list-item' key={id}>
                                        <CategoryCheckbox
                                            callback={handleChangeFilter}
                                            target={'Клик.Фильтр.'+item.Title}
                                            title={item.Title}
                                            icon={item && item.Icon && getUrlImage(item.Icon)}
                                            value={id}
                                            name={'category'}
                                            checked={activesCategory.indexOf(parseInt(id)) >= 0 && true || false}
                                        />
                                    </div>
                                )
                            })}
                            {/* {categoriesById && filterCategoryWithItems && filterCategoryWithItems.map((id, index) => {
                                let item = categoriesById[id];
                                return (
                                    <div className='filter__list-item' key={index}>
                                        <CategoryCheckbox
                                            callback={handleChangeFilter}
                                            target={'Клик.Фильтр.'+item.Title}
                                            title={item.Title}
                                            icon={item && item.Icon && getUrlImage(item.Icon)}
                                            value={id}
                                            name={'category'}
                                            checked={activesCategory.indexOf(id) >= 0 && true || false}
                                        />
                                    </div>
                                )
                            })} */}
                        </div>
                    </div>
                </div>
                {/* {statusShopsByFilter == 'LOADING' && (
                    <div className='page__loader'>
                        <Loader />
                    </div>
                )} */}
                {categoriesById && shopsByFilter && Object.keys(shopsByFilter).length > 0 && Object.values(shopsByFilter).map((item) => {

                    let categoryId = Object.keys(item)[0];

                    let data = [];

                    let meta = null;

                    if(Object.values(item)[0] && Object.values(item)[0].data){
                        data = Object.values(item)[0].data;
                    }

                    let sortData = [
                        ...categoriesById[categoryId].Shops.data
                    ];

                    // if(categoriesById[categoryId].Shops && categoriesById[categoryId].Shops.data){
                    //     categoriesById[categoryId].Shops.data.map(sortItem => {

                    //         if(data.findIndex(findItem => findItem.id == sortItem.id) >= 0){
                    //             sortData.push(sortItem);
                    //         }

                    //     })
                    // }

                    // data.map(shop => {
                    //     if(sortData.findIndex(findItem => findItem.id == shop.id) < 0){
                    //         sortData.push(shop);
                    //     }
                    // })

                    if(Object.values(item)[0] && Object.values(item)[0].meta){
                        meta = Object.values(item)[0].meta;
                    }

                    let total = categoriesById[categoryId].Shops.data.length;

                    // console.log();

                    if(data && !data.length){
                        return;
                    }

                    return (
                        <div className='page__category' key={categoryId}>
                            <div className='category _default _sale'>
                                <div className='category__header'>
                                    <div className='category__title'>
                                        <Link to={`/category/${categoryId}`} onClick={e => {
                                            targetMetrick(categoriesById[categoryId] && categoriesById[categoryId].Title + '.Показать все')
                                        }}>
                                            {categoriesById[categoryId] && categoriesById[categoryId].Title}
                                        </Link>
                                    </div>
                                    {data && data.length > 0 && (
                                        <Link to={`/category/${categoryId}`} onClick={e => {
                                            targetMetrick(categoriesById[categoryId] && categoriesById[categoryId].Title + '.Показать все')
                                        }} className='category__link'>Все {total}</Link>
                                    )}
                                </div>
                                {sortData && sortData.length > 0 && (
                                    <div className='category__list'>
                                        {sortData.map((item, index) => {

                                            if(categoriesById[categoryId].Count_posts_homepage && index >= categoriesById[categoryId].Count_posts_homepage){
                                                return;
                                            }

                                            const { id, attributes } = item;

                                            console.log(attributes, activesTypes);
                                            if(activesTypes.length > 0){
                                                if(activesTypes.indexOf(2) >= 0){
                                                    if(!attributes.Retail_store || !attributes.Retail_store.length){
                                                        return;
                                                    }
                                                }
                                                if(activesTypes.indexOf(1) >= 0){
                                                    if(!attributes.Web_store || !attributes.Web_store.length){
                                                        return;
                                                    }
                                                }
                                            }

                                            const { Title, Preview, Logo, Sale, Sale_card, Preview_list, Subtitle, Show_percent_on_preview } = attributes;

                                            let Percent = null;

                                            let Percent_symbol = null;

                                            let Percent_Symbol_Before = '-';
                                            
                                            if(Sale_card){
                                                if(Sale_card.Percent){
                                                    Percent = Sale_card.Percent;
                                                }
                                                if(Sale_card.Percent_symbol){
                                                    Percent_symbol = Sale_card.Percent_symbol;
                                                }
                                                if(Sale_card.Percent_Symbol_Before){
                                                    Percent_Symbol_Before = Sale_card.Percent_Symbol_Before;
                                                }
                                            } else {
                                                if(Sale && Sale.data && Sale.data.attributes && Sale.data.attributes.Sale_card){
                                                    if(Sale.data.attributes.Sale_card.Percent){
                                                        Percent = Sale.data.attributes.Sale_card.Percent;
                                                    }
                                                    if(Sale.data.attributes.Sale_card.Percent_symbol){
                                                        Percent_symbol = Sale.data.attributes.Sale_card.Percent_symbol;
                                                    }
                                                }
                                            }

                                            return (
                                                <div className='category__list-item' key={item.id}>
                                                    <PostShop
                                                        link={`/shop/${item.id}`}
                                                        preview={Preview_list && getUrlImage(Preview_list) || Preview && getUrlImage(Preview) || ''}
                                                        logo={Logo && getUrlImage(Logo) || ''}
                                                        sale={(Show_percent_on_preview || Show_percent_on_preview == null ) && Percent && Percent_Symbol_Before+Percent+( Percent_symbol && Percent_symbol || '%') || null}
                                                        title={Title}
                                                        category={categoriesById[categoryId].Title}
                                                        subTitle={Subtitle}
                                                        attributes={attributes}
                                                    />
                                                </div>
                                            )
                                        })}
                                    </div>
                                ) || (
                                    <span>В данной категории нет магазинов</span>
                                )}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Home;